<template>
    <div class="modal__bg" @click="closeModal">
      <div class="dialog" @click.stop>
        <svg class="icon-close" @click="closeModal" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z" fill="currentColor"/>
        </svg>
        <h2 class="dialog__title">{{title}}</h2>
        <p class="dialog__description" v-html="content"></p>
        <div class="dialog__buttons">
          <ButtonTexto
              type="button"
              v-if="cancel"
              @click="closeModal">
            {{cancel}}
          </ButtonTexto>
          <ButtonPrimary
              type="button"
              @click="acceptModal">
            {{accept}}
          </ButtonPrimary>
        </div>
      </div>

    </div>
</template>

<script>

import ButtonTexto from "@/components/new/buttons/ButtonTexto.vue";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary.vue";

export default {
  name: 'TwoButtonGenericModal',
  props: ['title', 'content', 'accept', 'cancel'],
  emits: ['close_modal', 'accepted_modal'],
  components: {
    ButtonPrimary, ButtonTexto

  },
  methods: {
    closeModal() {
      setTimeout(() => {
        this.$emit('close_modal')
      }, 10)
    },
    acceptModal() {
      setTimeout(() => {
        this.$emit('accepted_modal')
      }, 10)
    },
  }
}
</script>


<style scoped>

.dialog{
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: var(--text-white);
  box-shadow: 0 0 8px rgba(41, 41, 41, .2);
  border-radius: 16px;
  padding: 20px 10px;
  width: 100%;
  max-width: 600px;
  height: auto;
  max-height: 80vh;
  overflow-y: auto;
}

.icon-close{
  display: block;
  margin-left: auto;
  cursor: pointer;
  scale: 1;
  transition: scale .3s linear;
}

.icon-close:hover{
  color: var(--primary);
  cursor: pointer;
  scale: 1.5;
}

.dialog__title{
  color: var(--text-black);
  font-family: var(--headings-font-family);
  letter-spacing: 1px;
  font-weight: var(--medium);
  text-align: center;
  font-size: 30px;
  margin-top: -20px;
}

.dialog__description{
  color: var(--text-black);
  font-weight: var(--regular);
  text-align: center;
}

span{
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.dialog__buttons{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

@media only screen and (min-width: 768px){
  .dialog{ padding: 40px; }
}
</style>