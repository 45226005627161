<template>
  <article class="carta__card" style="background-image: url({{category.img}})" data-aos="fade-up">
    <div class="carta__nombre">
      <h3>{{category.name}}</h3>
    </div>
    <div class="carta__hover">
      <h3>{{category.name}}</h3>
      <ButtonSecundary>{{ $t('buttons.txt_ordena_ahora') }}</ButtonSecundary>
    </div>
  </article>
</template>

<script>
import ButtonSecundary from "@/components/new/buttons/ButtonSecundary.vue";

export default {
  name: 'CategoriasCarta',
  components: {ButtonSecundary},
  props: ['category'],
}

</script>

<style scoped>
  .carta__card{
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 150px;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
    box-shadow: var(--box-shadow);
  }

  .carta__card h3{
    color: var(--text-white);
    font-family: var(--headings-font-family);
    font-size: 28px;
    text-align: center;
  }

  .carta__nombre{
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    box-shadow: inset 0 0 400px rgba(0, 107, 174, 0.9);
    opacity: 1;
    transition: opacity .3s linear;
  }

  .carta__nombre h3{
    text-align: left;
    font-family: var(--headings-font-family);
    font-size: 23px;
  }

  .carta__hover{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transform: translateY(80%);
    width: 100%;
    height: 100%;
    opacity: 0;
    box-shadow: inset 0 0 400px rgba(0, 107, 174, 0.9);
    transition: transform .3s linear;
  }

  .carta__card:hover .carta__nombre{
    top: -100%;
    opacity: 0;
  }

  .carta__card:hover .carta__hover{
    opacity: 1;
    transform: translateY(0);
  }

  .carta__card:not(:hover) .carta__hover{
    transform: translateY(100%);
    opacity: 1
  }

  .carta__card:hover .carta__hover::before{
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 25%;
    background: var(--primary);
    animation: wave 3s ease-in-out infinite;
  }

  .carta__hover button{ text-transform: uppercase; }

  @keyframes wave {
    0%, 100% {
      clip-path: polygon(0 8%, 7% 6%, 14% 5%, 21% 5%, 28% 6%, 34% 8%, 40% 12%, 46% 17%, 50% 20%, 54% 23%, 60% 25%, 66% 26%, 70% 26%, 77% 25%, 83% 23%, 89% 21%, 95% 19%, 100% 17%, 100% 100%, 0% 100%);
    }

    50% {
      clip-path: polygon(0 15%, 9% 21%, 14% 23%, 18% 25%, 21% 26%, 30% 28%, 32% 28%, 40% 27%, 46% 26%, 52% 23%, 57% 19%, 62% 15%, 68% 11%, 73% 8%, 79% 6%, 86% 5%, 93% 5%, 100% 6%, 100% 100%, 0% 100%);
    }
  }

  @media only screen and (min-width: 390px){
    .carta__card{ height: 180px; }
  }

  @media only screen and (min-width: 425px){
    .carta__card{ height: 250px; }
  }

  @media only screen and (min-width: 768px){
    .carta__card{ width: 48%; }
  }

  @media only screen and (min-width: 1024px){
    .carta__card{ width: 425px; }
    .carta__nombre h3{ font-size: 28px; }
  }
</style>